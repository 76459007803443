import { NavLink, Outlet } from "react-router-dom";
import React, { useState, useRef, useEffect } from 'react';
import logga_stor from "../../logga_med_text.png";
import logga_liten from "../../logga192.png";

export default function LoginMenu() {

    const menuRef = useRef(null);

    const [settingsOpen, setSettingsOpen] = useState(false);

    const toggleSettings = () => {
        setSettingsOpen(!settingsOpen);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.settings-menu')) {
                setSettingsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div id="detail">
                <div className="header" ref={menuRef}>
                    <div className="settings-container">
                        <NavLink to="/" ><img src={logga_stor} alt="logga" style={{ height: '50px', margin: '0 15px 0 0' }} /></NavLink>
                    </div>
                    <div className="settings-container">
                        <button className="settings-toggle" onClick={toggleSettings}>
                            <i className="bi bi-three-dots" />
                        </button>
                        {settingsOpen && (
                            <div className="settings-menu">
                                <NavLink to="/login" onClick={toggleSettings}><i className="bi bi-box-arrow-in-right" style={{ paddingRight: '8px' }}/>Logga in</NavLink>

                            </div>
                        )}
                    </div>
                </div>
                <Outlet />
            </div>
        </>
    );
}