import React, { useContext } from 'react';
import { AuthContext } from '../AuthProvider';
import HeatingStatistics from '../components/statistics/HeatingStatistics';

const Statistics = () => {

    const { esp, espLoading } = useContext(AuthContext);
    
    
    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                    </div>
                </div>
                <div className="mt-3">
                    {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
                    {esp && (
                        <div>
                            <HeatingStatistics/>
                        </div>)}
                </div>

            </div>
        </div >
    )
}

export default Statistics;


