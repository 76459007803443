import { Form, Modal, Row, Col, Button } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import './HotWaterTimes.css';
import Parse from 'parse';

const HotWaterTimes = () => {
    const [showHelp, setShowHelp] = useState(false);
    const { esp, setUserEsp } = useContext(AuthContext);

    const handleCloseHelp = () => {
        setShowHelp(false);
    }

    const [timeInput, setTimeInput] = useState("");
    //const [tempInput, setTempInput] = useState("");

    // Function to handle adding a time
    const handleAddTimeAndTemp = () => {
        if (!esp.hotWater) {
            esp.hotWater = {};
        }
        if (!esp.hotWater.hotWaterTimes) {
            esp.hotWater.hotWaterTimes = [];
        }
        if (timeInput && !esp.hotWater.hotWaterTimes.some(item => item.time === timeInput)) {
            const timeAndTemp = { time: timeInput /*, temp: tempInput || 40 */ };

            const hotWaterTimes = [...esp.hotWater.hotWaterTimes, timeAndTemp].sort((a, b) => {
                return new Date(a.time) - new Date(b.time);
            });
            setTimeInput("");
            //setTempInput("");
            Parse.Cloud.run("updateUserEsp", { espId: esp.id, updates: { hotWater: { hotWaterTimes: hotWaterTimes } } }).then((result) => {
                setUserEsp(result);
                Parse.Cloud.run("updateHotWaterHours", { espId: esp.id }).then((hotWaterResult) => {
                    setUserEsp(hotWaterResult);
                });
            });

        }
    };

    // Function to handle input changes
    const handleTimeInputChange = (e) => {
        if (e.keyCode === 13) {
            handleAddTimeAndTemp();
        }
        setTimeInput(e.target.value);
    };

    /*
    const handleTempInputChange = (e) => {
        if (e.keyCode === 13) {
            handleAddTimeAndTemp();
        }
        setTempInput(e.target.value);
    };
    */

    // Function to remove a time from the list
    const handleRemoveTime = (timeToRemove) => {
        const hotWaterTimes = esp.hotWater.hotWaterTimes.filter((item) => item.time !== timeToRemove);
        Parse.Cloud.run("updateUserEsp", { espId: esp.id, updates: { hotWater: { hotWaterTimes: hotWaterTimes } } }).then((result) => {
            //setUserEsp(result);
            Parse.Cloud.run("updateHotWaterHours", { espId: esp.id }).then((hotWaterResult) => {
                setUserEsp(hotWaterResult);
            });
        });
    };

    // Handle Enter key press to submit the time
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
            handleAddTimeAndTemp(); // Trigger add time when Enter is pressed
        }
    };

    const isDisabled = (input) => {
        return input + (esp?.hotWater?.automationStatus ? "" : " disabled");
    }


    return (
        <>
            <div className="mt-3">
                <Form className="temp-form border-top mb-4">
                    <div className="d-flex align-items-center mt-3">
                        <h5>Tider för varmvatten</h5>
                        <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                    </div>
                    <Row className="align-items-center mb-3">
                        <Col xs={3}>
                            <Form.Label >Tidpunkt</Form.Label>
                            <Form.Control
                                type="time"
                                value={timeInput}
                                onChange={handleTimeInputChange}
                                onKeyDown={handleKeyPress}
                                placeholder="Enter time"
                                disabled={esp?.hotWater?.automationStatus ? false : true}
                            />
                        </Col>
                        {/* <Col xs={2}>
                            <Form.Label>Minsta temp</Form.Label>
                            <Form.Control
                                type="number"
                                value={tempInput || 40}
                                onChange={handleTempInputChange}
                                onKeyDown={handleKeyPress}
                                placeholder="Enter temperature"
                                disabled={esp?.hotWater?.automationStatus ? false : true}
                            />
                        </Col> */}
                        <Col xs={4}>
                            <Button onClick={handleAddTimeAndTemp} className="plus-btn" disabled={esp?.hotWater?.automationStatus ? false : true}>
                                <i className="bi bi-plus-lg"></i> {/* Bootstrap plus icon */}
                            </Button>
                        </Col>
                    </Row>

                    {/* Display the list of added times */}
                    <div className="times-list">
                        {esp.hotWater?.hotWaterTimes?.length > 0 ? (
                            <ul className="list-group">
                                {esp.hotWater?.hotWaterTimes.map((item, index) => (
                                    <li key={index} className={isDisabled("list-group-item d-flex justify-content-between align-items-center hot-water-item")}>
                                        <div className="hot-water-time">
                                            <i className="bi bi-clock" style={{ marginRight: '10px' }}></i>{item.time}
                                        </div>
                                        {/* <div className="hot-water-temp">
                                            <i className="bi bi-thermometer-high" style={{ marginRight: '10px' }}></i>{item.temp}°C
                                        </div> */}
                                        <Button className="minus-btn"
                                            variant="danger"
                                            onClick={() => handleRemoveTime(item.time)} // Remove time on click
                                            disabled={esp?.hotWater?.automationStatus ? false : true}
                                        >
                                            <i className="bi bi-dash-lg"></i> {/* Minus icon */}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>Inga angivna tider för varmvatten. Vattnet kommer värmas en gång per dygn när elen är som billigast.</p>
                        )}
                    </div>

                </Form>

            </div>
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tider för varmvatten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Vilka tider behövs varmvatten i hushållet som mest? Anges ingen tid värms vattnet en gång per dygn när det är som billigast, vanligtvis under natten. Ju färre tider som anges desto större möjlighet att spara in på elkostnaden.
                </Modal.Body>
            </Modal>
        </>

    )
}

export default HotWaterTimes;


