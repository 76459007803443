import React, { useContext } from "react";

import { AuthContext } from "../AuthProvider";
import HeatingButton from "../components/controlpanel/HeatingButton";
import HotWaterButton from "../components/controlpanel/HotWaterButton";
import AwayButton from "../components/controlpanel/AwayButton";
import WeatherButton from "../components/controlpanel/WeatherButton";
import PriceButton from "../components/controlpanel/PriceButton";
import CombinedSchedule from "../components/controlpanel/CombinedSchedule";

const ControlPanel = () => {

    const { esp, espLoading } = useContext(AuthContext);

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                    </div>
                </div>
                <div className="mt-3">
                    {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
                    {esp && (
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <PriceButton />
                                    <HeatingButton />
                                    <HotWaterButton />
                                    {/*<WeatherButton />*/}
                                    {window.innerWidth <= 768 && <AwayButton />}
                                </div>
                                {window.innerWidth > 768 && <AwayButton style={{ marginLeft: 'auto' }} />}
                            </div>
                            <CombinedSchedule />
                        </div>)}
                </div>

            </div>
        </div >

    )
}

export default ControlPanel;


