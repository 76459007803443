import { Form, Col, Row, Modal } from "react-bootstrap";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../AuthProvider";
import Parse from "parse";

const TempSettings = () => {

    const { esp, updateUserEsp, setUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [updateTimeout, setUpdateTimeout] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    const update = async (espId, updates) => {
        await updateUserEsp(espId, updates);
    }

    const scheduleUpdate = (espId) => {
        if (updateTimeout) clearTimeout(updateTimeout);

        const timeout = setTimeout(() => {
            Parse.Cloud.run("updateHeatingSchedule", { espId: espId })
                .then((scheduleResult) => {
                    setUserEsp(scheduleResult);
                });
        }, 2000);
        setUpdateTimeout(timeout);
    };

    const handleAwayTempInput = (change) => {
        const oldValue = esp?.heating?.tempSettings?.away?.temp || 15;
        const newValue = parseFloat((oldValue + change).toFixed(1));
        if (newValue > -1 && newValue < 31) {
            update(esp.id, { heating: { tempSettings: { away: { temp: newValue } } } }).then((result) => {
                scheduleUpdate(esp.id);
            });
        }
    };

    const handleMinTempInput = (change) => {
        const oldValue = esp?.heating?.tempSettings?.min?.temp || 18;
        const newValue = parseFloat((oldValue + change).toFixed(1));
        if (newValue > -1 && newValue < 31) {
            const updates = {
                min: { temp: newValue }
            };

            // Om min blir högre än ideal, justera ideal
            if (newValue > esp?.heating.tempSettings.ideal.temp) {
                updates.ideal = { temp: newValue };

                // Om ideal (nu samma som min) blir högre än max, justera max
                if (newValue > esp?.heating.tempSettings.max.temp) {
                    updates.max = { temp: newValue };
                }
            }
            update(esp.id, { heating: { tempSettings: updates } }).then((result) => {
                scheduleUpdate(esp.id);
            });
        }
    };

    const handleIdealTempInput = (change) => {
        const oldValue = esp?.heating?.tempSettings?.ideal?.temp || 20;
        const newValue = parseFloat((oldValue + change).toFixed(1));
        if (newValue > -1 && newValue < 31) {
            const updates = {
                ideal: { temp: newValue }
            };

            // Om ideal blir lägre än min, justera min
            if (newValue < esp?.heating.tempSettings.min.temp) {
                updates.min = { temp: newValue };
            }

            // Om ideal blir högre än max, justera max
            if (newValue > esp?.heating.tempSettings.max.temp) {
                updates.max = { temp: newValue };
            }
            update(esp.id, { heating: { tempSettings: updates } }).then((result) => {
                scheduleUpdate(esp.id);
            });
        }
    };

    const handleMaxTempInput = (change) => {
        const oldValue = esp?.heating?.tempSettings?.max?.temp || 23;
        const newValue = parseFloat((oldValue + change).toFixed(1));
        if (newValue > -1 && newValue < 31) {
            const updates = {
                max: { temp: newValue }
            };

            // Om max blir lägre än ideal, justera ideal
            if (newValue < esp?.heating.tempSettings.ideal.temp) {
                updates.ideal = { temp: newValue };

                // Om ideal (nu samma som max) blir lägre än min, justera min
                if (newValue < esp?.heating.tempSettings.min.temp) {
                    updates.min = { temp: newValue };
                }
            }
            update(esp.id, { heating: { tempSettings: updates } }).then((result) => {
                scheduleUpdate(esp.id);
            });
        }
    };

    return (
        <div>
            <Form className="temp-form border-top mb-4">
                <div className={`d-flex align-items-center mt-3 flex-row"}`}>
                    <h5>Temperaturreglering</h5>
                    <i className="bi bi-info-circle ms-2 mb-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                </div>
                <div className={`d-flex mt-3 ${isMobile ? "flex-column" : "flex-row align-items-center"}`}>
                    <div className={`d-flex ${isMobile ? "flex-row" : "flex-column"} align-items-center pe-3`}>
                        <div style={isMobile ? { width: '70px' } : {}}>
                            <strong>Bortrest</strong>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '120px' }}>
                            <i className="bi bi-caret-left-fill ms-2" onClick={() => handleAwayTempInput(-0.1)} style={{ cursor: 'pointer', color: '#007bff' }} />
                            <span className="mx-2">{esp?.heating.tempSettings.away.temp?.toFixed(1) ?? 'N/A'} °C</span>
                            <i className="bi bi-caret-right-fill" onClick={() => handleAwayTempInput(0.1)} style={{ cursor: 'pointer', color: '#ff4d4d' }} />
                        </div>
                    </div>

                    <div className={`d-flex ${isMobile ? "flex-row" : "flex-column"} align-items-center pe-3`}>
                        <div style={isMobile ? { width: '70px' } : {}}>
                            <strong>Min</strong>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '120px' }}>
                            <i className="bi bi-caret-left-fill ms-2" onClick={() => handleMinTempInput(-0.1)} style={{ cursor: 'pointer', color: '#007bff' }} />
                            <span className="mx-2">{esp?.heating.tempSettings.min.temp?.toFixed(1) ?? 'N/A'} °C</span>
                            <i className="bi bi-caret-right-fill" onClick={() => handleMinTempInput(0.1)} style={{ cursor: 'pointer', color: '#ff4d4d' }} />
                        </div>
                    </div>

                    <div className={`d-flex ${isMobile ? "flex-row" : "flex-column"} align-items-center pe-3`}>
                        <div style={isMobile ? { width: '70px' } : {}}>
                            <strong>Ideal</strong>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '120px' }}>
                            <i className="bi bi-caret-left-fill ms-2" onClick={() => handleIdealTempInput(-0.1)} style={{ cursor: 'pointer', color: '#007bff' }} />
                            <span className="mx-2">{esp?.heating.tempSettings.ideal.temp?.toFixed(1) ?? 'N/A'} °C</span>
                            <i className="bi bi-caret-right-fill" onClick={() => handleIdealTempInput(0.1)} style={{ cursor: 'pointer', color: '#ff4d4d' }} />
                        </div>
                    </div>

                    <div className={`d-flex ${isMobile ? "flex-row" : "flex-column"} align-items-center pe-3`}>
                        <div style={isMobile ? { width: '70px' } : {}}>
                            <strong>Max</strong>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '120px' }}>
                            <i className="bi bi-caret-left-fill ms-2" onClick={() => handleMaxTempInput(-0.1)} style={{ cursor: 'pointer', color: '#007bff' }} />
                            <span className="mx-2">{esp?.heating.tempSettings.max.temp?.toFixed(1) ?? 'N/A'} °C</span>
                            <i className="bi bi-caret-right-fill" onClick={() => handleMaxTempInput(0.1)} style={{ cursor: 'pointer', color: '#ff4d4d' }} />
                        </div>
                    </div>
                </div>
            </Form >
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Temperaturreglering</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Här ställer du in ditt önskade spann för inomhustemperatur. Inom spannet min-max tillåts inomhusvärmen variera under dygnet. Den högsta värmen används för att buffra värme i huset vid lågt elpris för att sedan låta det sjunka till minsta temperaturen under de dyraste timmarna. Bortrest anger den minsta temperatur som skall hållas för att huset inte skall påverkas negativt av fukt eller frysskador när du är bortrest.
                </Modal.Body>
            </Modal>
        </div >

    )
}

export default TempSettings;


