import React, { createContext, useState, useEffect } from "react";
import Parse from "parse";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayName, setDisplayName] = useState(null);
  const [esp, setEsp] = useState(null);
  const [espLoading, setEspLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if a user is already logged in
    const currentUser = Parse.User.current();
    if (currentUser) {
      setUser(currentUser);
      setDisplayName(currentUser.get("name"));
    } else {
      navigate("/login");
    }
    setLoading(false);
  }, [navigate]);

  // 1: Load user esp(s)
  useEffect(() => {
    async function loadEsp() {
      if (user) {
        const esps = await Parse.Cloud.run("getUserEsps");
        const esp = esps[0];
        if (esp) {
          setEsp(esp);
        }
        setEspLoading(false);
      }
    }
    loadEsp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const runEspQuery = async () => {
      const query = new Parse.Query("Esp");
      query.equalTo("objectId", esp?.objectId);
      const subscription = await query.subscribe();

      subscription.on("update", (updatedEsp) => {
          setEsp(parseEsp(updatedEsp));
      });

      return () => {
        subscription.unsubscribe();
      };
    };
    runEspQuery();
  }, [esp?.objectId]);

  const parseEsp = (esp) => {
    const espData = esp.toJSON();
    return {
      ...espData,
      id: espData.objectId,
    };
}

  const login = async (username, password) => {
    try {
      const loggedInUser = await Parse.User.logIn(username, password);
      setUser(loggedInUser);
      return loggedInUser;
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setDisplayName(null);
      setEsp(null);
      setEspLoading(true);
    } catch (error) {
      console.error("Logout failed", error);
      throw error;
    }
  };

  const updateUserEsp = async (espId, updates) => {
    const esp = await Parse.Cloud.run("updateUserEsp", {
      espId: espId,
      updates: updates,
    });
    setEsp(esp);
  };

  const setUserEsp = async (esp) => {
    setEsp(esp);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        displayName,
        login,
        logout,
        loading,
        esp,
        espLoading,
        updateUserEsp,
        setUserEsp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
