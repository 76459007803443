import React, { useContext, useState } from "react";
import { PriceContext } from "../../PriceProvider";
import Modal from 'react-bootstrap/Modal';
import './PriceButton.css';

const PriceButton = () => {
    const { loading, currentPrice, lowestPrice, highestPrice, meanPrice, priceCategory } = useContext(PriceContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClick = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    if (loading) {
        return (
            <button onClick={handleClick} className="controlpanel-button">Laddar</button>
        )

    }
    return (
        <>
            <button onClick={handleClick} className="controlpanel-button">
                {priceCategory === "LOW" &&
                    <>
                        <i className="bi bi-caret-down text-success" style={{ fontSize: '115px', position: 'absolute', top: '-25px' }} />
                        <span style={{ fontSize: '18px', position: 'absolute', top: '10px' }}>{currentPrice} kr</span>
                        <span style={{ fontSize: '12px', position: 'absolute', bottom: '40px' }}>Elpris</span>
                    </>
                }
                {priceCategory === "MIDDLE" &&
                    <>
                        <i className="bi bi-caret-right text-warning" style={{ fontSize: '115px', position: 'absolute', top: '-36px' }} />
                        <span style={{ fontSize: '18px', position: 'absolute', top: '40px' }}>{currentPrice} kr</span>
                        <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Elpris</span>
                    </>
                }
                {priceCategory === "HIGH" &&
                    <>
                        <i className="bi bi-caret-up text-danger" style={{ fontSize: '115px', position: 'absolute', top: '-45px' }} />
                        <span style={{ fontSize: '18px', position: 'absolute', top: '40px' }}>{currentPrice} kr</span>
                        <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Elpris</span>
                    </>
                }
            </button>

            <Modal show={modalIsOpen} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Dagens elpriser</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`prices d-flex align-items-center`}>
                        <div className={`price-couple text-center`}>
                            <div>
                                <span className="price-title">Just nu</span>
                            </div>
                            <div>
                                <span className="price me-1 text-secondary">{currentPrice}</span>
                            </div>
                            <div>
                                <span className="price-unit">kr/kWh</span>
                            </div>
                        </div>
                        <div className={`price-couple text-center`}>
                            <div>
                                <span className="price-title">Lägsta</span>
                            </div>
                            <div>
                                <span className="price me-1 text-success">{lowestPrice}</span>
                            </div>
                            <div>
                                <span className="price-unit">kr/kWh</span>
                            </div>
                        </div>
                        <div className={`price-couple text-center`}>
                            <div>
                                <span className="price-title">Högsta</span>
                            </div>
                            <div>
                                <span className="price me-1 text-danger">{highestPrice}</span>
                            </div>
                            <div>
                                <span className="price-unit">kr/kWh</span>
                            </div>
                        </div>
                        <div className={`price-couple text-center`}>
                            <div>
                                <span className="price-title">Snitt</span>
                            </div>
                            <div>
                                <span className="price me-1 text-warning">{meanPrice}</span>
                            </div>
                            <div>
                                <span className="price-unit">kr/kWh</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PriceButton;