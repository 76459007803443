import { Form, Modal } from "react-bootstrap";
import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../AuthProvider";
import Parse from "parse";

const PeakLengthSetting = () => {

    const { esp, updateUserEsp, setUserEsp } = useContext(AuthContext);

    const [showHelp, setShowHelp] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCloseHelp = () => setShowHelp(false);

    const handleShowHelp = () => setShowHelp(true);

    const update = async (espId, updates) => {
        await updateUserEsp(espId, updates);
    }

    const handleInput = (change) => {
        const newValue = (esp?.heating?.peakLengthSetting || 3) + change;
        if (newValue > 0 && newValue < 11) {
            update(esp.id, { heating: { peakLengthSetting: newValue} }).then((result) => {
                Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id })
                .then((scheduleResult) => {
                    setUserEsp(scheduleResult);
                });
            });
        }
    };
    return (
        <div>
            <Form className="temp-form border-top mb-4">
                <div className={`d-flex align-items-center mt-3 flex-row"}`}>
                    <h5>Maxlängd på pik</h5>
                    <i className="bi bi-info-circle ms-2 mb-2" onClick={handleShowHelp} style={{ cursor: 'pointer', color: '#007bff' }} />
                </div>
                <div className={`d-flex mt-3 ${isMobile ? "flex-column" : "flex-row align-items-center"}`}>
                    <div className={`d-flex ${isMobile ? "flex-row" : "flex-column"} align-items-center pe-3`}>
                        <div style={isMobile ? { width: '70px' } : {}}>
                            <strong>Maxlängd</strong>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '120px' }}>
                            <i className="bi bi-dash ms-2" onClick={() => handleInput(-1)} style={{ cursor: 'pointer', color: '#007bff' }} />
                            <span className="mx-2">{esp?.heating?.peakLengthSetting || 3 }</span>
                            <i className="bi bi-plus" onClick={() => handleInput(1)} style={{ cursor: 'pointer', color: '#ff4d4d' }} />
                        </div>
                    </div>
                </div>
            </Form >
            <Modal show={showHelp} onHide={handleCloseHelp} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Maxlängd på pik</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Ställ in hur många timmar som mest i rad du vill använda min- eller max-temperaturen.
                </Modal.Body>
            </Modal>
        </div >

    )
}

export default PeakLengthSetting;


