import { NavLink, Outlet } from "react-router-dom";
import React, { useContext, useState, useRef, useEffect } from 'react';
import { AuthContext } from "../AuthProvider";
import ChooseEsp from "../components/esp/ChooseEsp";
import logga_stor from "../logga_med_text.png";
import logga_liten from "../logga192.png";

export default function Menu() {

    const { logout } = useContext(AuthContext);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleSettings = () => {
        setSettingsOpen(!settingsOpen);
    }

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from the context
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setSettingsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div id="detail">
                <div className="header">
                    <div className="settings-container">
                        <NavLink to="/" ><img src={window.innerWidth > 768 ? logga_stor : logga_liten} alt="logga" style={{height: '50px', margin: '0 15px 0 0'}} /></NavLink>
                        <ChooseEsp />
                    </div>
                    <div className="settings-container">
                        <button className="settings-toggle" onClick={toggleSettings}>
                            <i className="bi bi-three-dots" />
                        </button>
                        {settingsOpen && (
                            <div className="settings-menu" ref={menuRef}>
                                <NavLink to="/" onClick={toggleSettings}><i className="bi bi-robot" style={{ paddingRight: '8px' }}/>Smart styrning</NavLink>
                                <NavLink to="/account" onClick={toggleSettings}><i className="bi bi-person-circle" style={{ paddingRight: '8px' }}/>Konto</NavLink>
                                <NavLink to="/statistics" onClick={toggleSettings}><i className="bi bi-bar-chart-line" style={{ paddingRight: '8px' }}/>Statistik</NavLink>
                                <NavLink to="/" onClick={handleLogout}><i className="bi bi-box-arrow-right" style={{ paddingRight: '8px' }}/>Logga ut</NavLink>
                            </div>
                        )}
                    </div>
                </div>
                <Outlet />
            </div>
        </>
    );
}