import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../AuthProvider";
import { format } from "date-fns";
import Modal from 'react-bootstrap/Modal';
import { Button, Spinner, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import Parse from 'parse';
import "react-datepicker/dist/react-datepicker.css";
import "./AwayButton.css";

const AwayButton = () => {
    const { esp, updateUserEsp, setUserEsp, espLoading } = useContext(AuthContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [now, setNow] = useState(new Date());
    const awayEnds = esp?.awayEnds ? new Date(esp?.awayEnds?.iso ? esp.awayEnds.iso : esp?.awayEnds) : null;
    const [selectedDate, setSelectedDate] = useState(awayEnds && awayEnds > now ? awayEnds : null);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const handleClick = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setShowHelp(false);
    };

    const saveSelectedDate = () => {
        if (selectedDate > now) {
            updateUserEsp(esp.id, { awayEnds: selectedDate })
                .then(() => {
                    setTimeout(() => {
                        Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                            .then(() => Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id }))
                            .then((result) => setUserEsp(result));
                    }, 20);
                });
            closeModal();
        } else {
            toast.error("Planerad hemkomst måste vara i framtiden.");
        }
    };

    const clearDate = () => {
        updateUserEsp(esp.id, { awayEnds: null })
            .then(() => {
                setTimeout(() => {
                    Parse.Cloud.run("updateHotWaterHours", { espId: esp.id })
                        .then(() => Parse.Cloud.run("updateHeatingSchedule", { espId: esp.id }))
                        .then((result) => setUserEsp(result));
                }, 20);
            });
        setSelectedDate(null);
        closeModal();
    };

    const handleHelpClick = () => {
        setShowHelp(true);
    };

    const handleBackClick = () => {
        setShowHelp(false);
    };

    return (
        <>
            <button onClick={handleClick} className="controlpanel-button">
                {awayEnds && awayEnds > now ?
                    <>
                        <i className="bi bi-luggage" style={{ color: "CornflowerBlue", fontSize: '80px', position: 'absolute', top: '-10px' }} />
                        <span style={{ fontSize: '12px', position: 'absolute', top: '10px' }}>{format(awayEnds, 'd/M HH:mm')}</span>
                        <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Hemkomst</span>
                    </>
                    :
                    <>
                        <i className="bi bi-luggage" style={{ color: "LightGrey", fontSize: '80px', position: 'absolute', top: '-10px' }} />
                        <span style={{ fontSize: '14px', position: 'absolute', bottom: '10px' }}>Åka bort?</span>
                    </>
                }
            </button>

            <Modal show={modalIsOpen} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bortaläge
                        {!showHelp && <i className="bi bi-info-circle ms-2 mb-2" onClick={handleHelpClick} style={{ cursor: 'pointer', color: '#007bff' }} />}
                        {showHelp && <i className="bi bi-x-circle ms-2 mb-2" onClick={handleBackClick} style={{ cursor: 'pointer', color: '#007bff' }} />}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showHelp ? (
                        <div>
                            <p>Ställ in datum för hemkomst för att starta bortaläge och spara på el medan du är iväg. Värmen kommer hållas på nivån inställd under "bortrest" och varmvattnet kommer vara avstängt. Värme och varmvatten kommer att startas igång för att värmas vid billigast möjliga tillfälle och vara redo när du kommer hem.</p>
                        </div>
                    ) : (
                        <div>
                            <div className="p-3 mb-3">
                                <div className="d-flex align-items-center">
                                    {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                                    {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                                </div>
                                {esp && (<h5 className="mb-0">{awayEnds && awayEnds > now ? (<i className="bi bi-luggage-fill text-primary" />) : (<i className="bi bi-luggage-fill text-secondary" />)}&nbsp;&nbsp;
                                    {awayEnds && awayEnds > now ? (
                                        <>Aktivt till {format(awayEnds, 'd/M HH:mm')}</>
                                    ) : (
                                        <>Bortaläge ej aktivt</>
                                    )}</h5>)}

                                <div className="mt-3">
                                    <Form>
                                        <Form.Group controlId="dateTimePicker">
                                            <div className="d-flex flex-column flex-md-row align-items-center" style={{ width: '100%', justifyContent: 'space-between' }}>
                                                <div className="d-flex flex-column flex-md-row align-items-center" style={{ width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
                                                    <strong className="me-2 mb-2">Planerad hemkomst</strong>
                                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            onChange={(date) => setSelectedDate(date)}
                                                            dateFormat="yyyy-MM-dd"
                                                            placeholderText="Datum"
                                                            className={window.innerWidth < 768 ? "awaydatepicker-small awaydatepicker-small-date" : "awaydatepicker awaydatepicker-date"}
                                                        />

                                                        <DatePicker
                                                            selected={selectedDate}
                                                            onChange={(time) => {
                                                                const currentDate = selectedDate || new Date();
                                                                setSelectedDate(new Date(currentDate.setHours(time.getHours(), time.getMinutes())));
                                                            }}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            timeFormat="HH:mm"
                                                            dateFormat="HH:mm"
                                                            placeholderText="Tid"
                                                            className={window.innerWidth < 768 ? "awaydatepicker-small" : "awaydatepicker awaydatepicker-time"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-column flex-md-row align-items-center" style={{ width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <div><Button variant="primary" onClick={saveSelectedDate} style={{ width: '175px' }} className="mb-2">Spara och stäng</Button></div>
                                                <div>
                                                    {awayEnds && awayEnds > now &&
                                                        <Button variant="danger" onClick={clearDate} style={{ width: '175px' }} className="mb-2">
                                                            Avsluta bortaläge
                                                        </Button>
                                                    }
                                                    {(!awayEnds || awayEnds <= now) &&
                                                        <Button variant="secondary" onClick={clearDate} style={{ width: '175px' }} className="mb-2">
                                                            Avbryt
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div >
                    )}
                </Modal.Body >
            </Modal >
        </>
    );
};

export default AwayButton;