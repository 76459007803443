import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../AuthProvider";

const HotWaterButton = () => {
    const navigate = useNavigate();
    const { esp } = useContext(AuthContext);

    const handleClick = () => {
        navigate('/hotwater');
    };

    return (
        <button onClick={handleClick} className="controlpanel-button">
            <i className="bi bi-droplet" style={{ color: esp?.hotWater?.automationStatus? "#FF8C00" : "lightgrey", fontSize: '90px', position: 'absolute', top: '-18px' }} />
            <span style={{ fontSize: '18px', position: 'absolute', top: '40px' }}>{esp?.sensorData?.hotWaterTemp?.toFixed(1)}°C</span>
            <span style={{ fontSize: '12px', position: 'absolute', bottom: '10px' }}>Varmvatten</span>
        </button>
    );
};

export default HotWaterButton;