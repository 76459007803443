import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import Parse from "parse";
import ReactECharts from 'echarts-for-react';
import { format } from "date-fns";

const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

const ECHART_CONTRAST_COLOR = isDarkMode ? '#B9B8CE' : '#666';
const ECHART_AXIS_LINE_COLOR = isDarkMode ? '#B9B8CE': '#666';
const ECHART_SPLIT_LINE_COLOR = isDarkMode ? '#555' : '#eee';

const HeatingStatistics = () => {

    const [data, setData] = useState(null);
    const [minScale, setMinScale] = useState(-0.1);
    const [maxScale, setMaxScale] = useState(1);
    const [loading, setLoading] = useState(true);
    const { esp } = useContext(AuthContext);



    useEffect(() => {
        const getStatistics = async () => {
            try {
                let yesterdayTime = new Date(new Date().setDate(new Date().getDate() - 1));
                yesterdayTime.setHours(12, 55, 0, 0);
                const result = await Parse.Cloud.run("getHeatingStatistics", { espId: esp.id, times: { startTime: yesterdayTime, endTime: new Date() } });
                let yesterdayPriceTime = new Date(new Date().setDate(new Date().getDate() - 1));
                yesterdayPriceTime.setHours(11, 55, 0, 0);
                const prices = await Parse.Cloud.run("getPriceStatistics", { times: { startTime: yesterdayPriceTime, endTime: new Date() } });

                // Skapa en array för att lagra den sammanslagna datan
                const combinedData = [];

                // Bearbeta datan för att kombinera värden baserat på tidpunkten
                let minTempValue = null;
                let maxTempValue = null;
                result.forEach(item => {
                    if(!minTempValue || item._value < minTempValue){
                        minTempValue = item._value;
                    }
                    if(!maxTempValue || item._value > maxTempValue){
                        maxTempValue = item._value;
                    }

                    const timeKey = item._time;

                    const existingEntry = combinedData.find(entry => entry.time === timeKey);
                    if (existingEntry) {
                        existingEntry[item._field] = item._value ? item._value.toFixed(1) : null; // Lägg till värdet i det befintliga objektet
                    } else {
                        combinedData.push({ time: timeKey, [item._field]: item._value ? item._value.toFixed(1) : null }); // Skapa ett nytt objekt
                    }
                });

                // Slå samman prisdata med den kombinerade datan
                prices.forEach(priceItem => {
                    const timeKey = priceItem._time;
                    const existingEntry = combinedData.find(entry => entry.time === timeKey);
                    if (existingEntry) {
                        existingEntry.price = priceItem._value ? (priceItem._value).toFixed(2) : null; // Lägg till priset i det befintliga objektet
                    }
                });

                // Sätt pris var 5:e min, finns annars bara en gång i timmen
                let hourlyPrice = null;
                let minPrice = null;
                let maxPrice = null;
                combinedData.forEach(entry => {
                    if (entry.price) {
                        hourlyPrice = entry.price;
                        if(!minPrice || entry.price < minPrice){
                            minPrice = entry.price;
                        }
                        if(!maxPrice || entry.price > maxPrice){
                            maxPrice = entry.price;
                        }
                    } else {
                        entry.price = hourlyPrice;
                    }
                })

                // Sätt datan i state
                const sortedData = combinedData.sort((a, b) => new Date(a.time) - new Date(b.time));
                setData(sortedData);
                
                //1. Minsta på skalan skall vara noll om inget negativt värde finns
                //2. minScale rundas nedåt till närmsta nedre 0.1, det lägsta av pris och temp används. Temp delas med 100 för att få samma storlek som pris och multipliceras sen med 100 när det visas
                //3. maxScale samma som minScale men avrundas uppåt och strunta i noll-logik
                // Värdena för de olika y-axlarna jämförs och jämkas mot varandra för att diagrammet ser knäppt ut om de har olika avstånd. Hanteras inte så snyggt av echarts.
                setMinScale(minPrice > 0 && minTempValue > 0 ? 0 : Math.floor((minPrice < minTempValue/10 ? minPrice : minTempValue/10) * 10) / 10);
                setMaxScale(Math.ceil((maxPrice > maxTempValue/10 ? maxPrice : maxTempValue/10) * 10) / 10);
            } catch (error) {
                console.error("Fel vid hämtning av data:", error);
            } finally {
                setLoading(false);
            }
        };

        getStatistics();
    }, [esp.id]);

    return (
        <div>
            {loading && "Laddar"}
            {!loading && data && (
                <ReactECharts
                    option={{
                        title: {
                            text: 'Värmning',
                            textStyle: {
                                color: ECHART_CONTRAST_COLOR,
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                            },
                            position: (point, params, dom, rect, size) => {
                                // Beräkna positionen för tooltip
                                const tooltipX = (point[0] + size.contentSize[0] > window.innerWidth)
                                    ? point[0] - size.contentSize[0] - 10 // Visa till vänster om den är för nära höger kant
                                    : point[0] + 10; // Visa till höger annars
                                const tooltipY = point[1] - size.contentSize[1] / 2; // Centrera vertikalt
                                return [tooltipX, tooltipY];
                            },
                        },
                        legend: {
                            data: [
                                'Mål inomhustemperatur',
                                'Faktisk inomhustemperatur',
                                'Luftfuktighet',
                                'Returledingstemperatur',
                                'Framledningstemperatur',
                                'Elpris'
                            ],
                            orient: 'horizontal',
                            top: 'bottom',
                            textStyle: {
                                color: ECHART_CONTRAST_COLOR,
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: data.map(item => {
                                const date = new Date(item.time);
                                return format(date, 'yyyy-MM-dd HH:mm');
                            }),
                            axisLabel: {
                                formatter: (value) => {
                                    const date = new Date(value);
                                    return format(date, 'HH:mm');
                                },
                                interval: window.innerWidth > 768 ? 23 : 35,
                                rotate: window.innerWidth <= 768 ? 45 : 0,
                                color: ECHART_CONTRAST_COLOR,
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: 'Temperatur (°C)',
                                position: 'right',
                                nameTextStyle: {
                                    align: window.innerWidth <= 768 ? 'right' : 'end',
                                    color: ECHART_CONTRAST_COLOR,
                                },
                                min: (minScale*10).toFixed(1),
                                max: (maxScale*10).toFixed(1),
                                axisLine: {
                                    lineStyle: {
                                        color: ECHART_AXIS_LINE_COLOR,
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: ECHART_SPLIT_LINE_COLOR,
                                    }
                                },
                            },
                            {
                                type: 'value',
                                name: 'Pris (kr/kWh)',
                                position: 'left',
                                nameTextStyle: {
                                    align: window.innerWidth <= 768 ? 'left' : 'end',
                                    color: ECHART_CONTRAST_COLOR,
                                },
                                min: minScale.toFixed(1),
                                max: maxScale.toFixed(1),
                                axisLine: {
                                    lineStyle: {
                                        color: ECHART_AXIS_LINE_COLOR,
                                    }
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: ECHART_SPLIT_LINE_COLOR,
                                    }
                                },
                            }
                        ],
                        series: [
                            {
                                name: 'Mål inomhustemperatur',
                                type: 'line',
                                data: data.map(item => item.heatingGoalTemp),
                                yAxisIndex: 0,
                            },
                            {
                                name: 'Faktisk inomhustemperatur',
                                type: 'line',
                                data: data.map(item => item.indoorTemp),
                                yAxisIndex: 0,
                            },
                            {
                                name: 'Luftfuktighet',
                                type: 'line',
                                data: data.map(item => item.indoorHumidity),
                                yAxisIndex: 0,
                            },

                            {
                                name: 'Returledingstemperatur',
                                type: 'line',
                                data: data.map(item => item.returnLineTemp),
                                yAxisIndex: 0,
                            },
                            {
                                name: 'Framledningstemperatur',
                                type: 'line',
                                data: data.map(item => item.supplyLineTemp),
                                yAxisIndex: 0,
                            },
                            {
                                name: 'Elpris',
                                type: 'bar',
                                color: 'lightgrey',
                                data: data.map(item => item.price),
                                yAxisIndex: 1,
                            }
                        ],
                        grid: {
                            top: '10%',
                            bottom: '20%',
                        },
                    }}
                    style={{ height: '650px', width: '100%' }}
                />
            )}
        </div>
    );
};

export default HeatingStatistics;
