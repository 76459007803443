import React, { useContext, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Modal } from "react-bootstrap";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell,
    Line,
    ReferenceLine,
    Legend,
} from 'recharts';
import { AuthContext } from "../../AuthProvider";
import Parse from "parse";

const CombinedSchedule = ({ showOnlyHeating, showOnlyHotWater }) => {
    const [showHelp, setShowHelp] = useState(false);
    const [selectedHourData, setSelectedHourData] = useState(null);
    const { esp, updateUserEsp } = useContext(AuthContext);
    const [now, setNow] = useState(new Date());
    const heatingSchedule = esp?.heating?.heatingSchedule?.slice(12) ?? [];
    const hotWaterSchedule = esp?.hotWater?.heatingPlan?.slice(12) ?? [];
    const awayEnds = esp?.awayEnds?.iso ? new Date(esp.awayEnds.iso) : esp?.awayEnds;

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const combinedMap = new Map();

    heatingSchedule.forEach(heatingItem => {
        const hour = heatingItem?.hour?.iso ? new Date(heatingItem.hour.iso) : heatingItem?.hour;
        return combinedMap.set(format(hour, 'yyyy-MM-dd HH:mm'), {
            price: heatingItem.price,
            heatingSetting: heatingItem.setting,
            heatingGoalTemp: heatingItem.goalTemp,
            heatingCustomHour: heatingItem.customHour,
            hourOriginal: hour,
            hour: format(hour, 'HH:mm'),
            fullDate: format(hour, 'd MMM HH:mm'),
            dateTime: format(hour, 'yyyy-MM-dd HH:mm'),
            isCurrentHour: format(hour, 'yyyy-MM-dd HH') === format(now, 'yyyy-MM-dd HH'),
            awayEnds: format(hour, 'yyyy-MM-dd HH') === format(awayEnds, 'yyyy-MM-dd HH')
        });
    });

    hotWaterSchedule.forEach(hotWaterItem => {
        const hotWaterTime = format(hotWaterItem?.hour?.iso ? new Date(hotWaterItem.hour.iso) : hotWaterItem.hour, 'yyyy-MM-dd HH:mm');
        if (combinedMap.has(hotWaterTime)) {
            Object.assign(combinedMap.get(hotWaterTime), {
                isHotWaterHeating: hotWaterItem.isHeating,
                isHotWaterCustomHeating: hotWaterItem.isCustomHeating,
                scheduledHotWaterTimes: hotWaterItem.scheduledTimes,
                hotWaterCalculatedTemperature: hotWaterItem.calculatedTemperature
            });
        } else {
            combinedMap.set(hotWaterTime, {
                isHotWaterHeating: hotWaterItem.isHeating,
                isHotWaterCustomHeating: hotWaterItem.isCustomHeating,
                scheduledHotWaterTimes: hotWaterItem.scheduledTimes,
                hotWaterCalculatedTemperature: hotWaterItem.calculatedTemperature
            });
        }
    });

    const combinedSchedule = Array.from(combinedMap.values());

    const handleHourClickHeating = (clickedData) => {
        setSelectedHourData(clickedData.heatingCustomHour ? {
            ...clickedData.heatingCustomHour,
            hourOriginal: clickedData.heatingCustomHour.hour.iso ? clickedData.heatingCustomHour.hour.iso : clickedData.heatingCustomHour.hour,
            isExistingCustomHour: true,
        } : clickedData);
    };

    const handleHourClickHotWater = (clickedData) => {
        let customHourSettings = esp.hotWater.customHourSettings || [];

        const existingIndex = customHourSettings.findIndex(hour =>
            format(hour.hour.iso || hour.hour, 'yyyy-MM-dd HH') === format(clickedData.hourOriginal, 'yyyy-MM-dd HH')
        );
        if (existingIndex !== -1) {
            customHourSettings.splice(existingIndex, 1);
        } else {
            let selectedHour = { hour: clickedData.hourOriginal, isCustomHeating: !clickedData.isHotWaterHeating };
            customHourSettings.push(selectedHour);
        }
        updateUserEsp(esp.id, {
            hotWater: {
                customHourSettings: customHourSettings
            }
        }).then(() => {
            return Parse.Cloud.run("updateHotWaterHours", {
                espId: esp.id,
            });
        });
    }

    const handleCloseModal = () => {
        setShowHelp(false);
        setSelectedHourData(null);
    };

    const handleClear = () => {
        if (selectedHourData.isExistingCustomHour) {
            let customHourSettings = esp.heating.customHourSettings || [];
            const existingIndex = customHourSettings.findIndex(hour =>
                format(hour.hour.iso || hour.hour, 'yyyy-MM-dd HH') ===
                format(selectedHourData.hourOriginal, 'yyyy-MM-dd HH')
            );
            if (existingIndex !== -1) {
                customHourSettings.splice(existingIndex, 1);
                updateUserEsp(esp.id, {
                    heating: {
                        customHourSettings: customHourSettings
                    }
                }).then(() => {
                    return Parse.Cloud.run("updateHeatingSchedule", {
                        espId: esp.id,
                    });
                });
            }
        }
        handleCloseModal();
    };

    const saveSelectedHourChanges = (data) => {
        let customHourSettings = esp.heating.customHourSettings || [];
        if (selectedHourData.isExistingCustomHour) {
            const existing = customHourSettings.find(hour =>
                format(hour.hour.iso || hour.hour, 'yyyy-MM-dd HH') ===
                format(selectedHourData.hourOriginal, 'yyyy-MM-dd HH')
            );
            if (existing) {
                existing.numberOfHours = data.numberOfHours;
                existing.heatingGoalTemp = data.heatingGoalTemp;
            }
        } else {
            let selectedHour = { hour: data.hourOriginal, numberOfHours: data.numberOfHours || 1, heatingGoalTemp: data.heatingGoalTemp };
            customHourSettings.push(selectedHour);
        }
        updateUserEsp(esp.id, {
            heating: {
                customHourSettings: customHourSettings
            }
        }).then(() => {
            return Parse.Cloud.run("updateHeatingSchedule", {
                espId: esp.id,
            });
        });
    };

    const hasValidSchedule = Array.isArray(heatingSchedule) && heatingSchedule.length > 0;

    // Definiera färgkonstanter
    const COLORS = {
        AWAY: '#4A90E2',  // En kall blå som signalerar bortaläge
        MIN: '#E74C3C',    // En varm röd som matchar intensiteten
        IDEAL: '#FFB405',  // Den gula/orange du specificerade
        MAX: '#4FB06D',    // En mjuk grön som kompletterar gul
        CUSTOM: '#8E5B9B',
        INDOORTEMP: '#2ECC71', // En frisk grön färg för att representera nuvarande temperatur
        HOTWATER_HEATING: '#FF8C00',
        HOTWATER_CUSTOM_HEATING: '#FF6200',
        HOTWATERTEMP: '#801818', // Den röda färgen för temperatur
        ELPRIS: '#7D7D7D', // Standardfärg för elpris
        ELPRIS_TOOLTIP: '#7D7D7D', // en mörkare grå för att synas bättre
        SCHEDULE_TIME: '#E74C3C',    // En varm röd som matchar intensiteten
    };

    const sortedPrices = [...combinedSchedule].sort((a, b) => a.price - b.price);
    const highestPrice = sortedPrices[sortedPrices.length - 1].price;
    const lowestPrice = sortedPrices[0].price;
    const domainPriceHighest = highestPrice < 2 ? 2 : highestPrice < 5 ? 5 : highestPrice < 10 ? 10 : 20;
    const domainPriceLowest = lowestPrice >= 0 ? 0 : -1;

    return (
        <>
            <div>
                <div className="d-flex align-items-center mt-3">
                    {showOnlyHeating && <h5>Planerad värmning inomhus</h5>}
                    {showOnlyHotWater && <h5>Planerad värmning av varmvatten</h5>}
                    {!showOnlyHeating && !showOnlyHotWater && <h5>Planerad värmning och varmvatten</h5>}
                    <i className="bi bi-info-circle ms-2 mb-2" onClick={() => setShowHelp(true)} style={{ cursor: 'pointer', color: '#007bff' }} />
                </div>

                {awayEnds && awayEnds > now && (<div className="mb-3 p-3 border rounded bg-light">
                    <div className="mb-2">
                        <i className="bi bi-luggage-fill me-2"></i>
                        {showOnlyHotWater && <span className="text-primary">Bortaläge slutar {format(awayEnds, 'd MMM HH:mm')}. En extra värmning av varmvatten har schemalagts inför det.</span>}
                        {showOnlyHeating && <span className="text-primary">Bortaläge slutar {format(awayEnds, 'd MMM HH:mm')}. Rumsvärmen aktiveras i tid för att hinna nå önskad temperatur.</span>}
                        {!showOnlyHeating && !showOnlyHotWater && <span className="text-primary">Bortaläge slutar {format(awayEnds, 'd MMM HH:mm')}. En extra värmning av varmvatten har schemalagts inför det och rumsvärmen aktiveras i tid för att hinna nå önskad temperatur.</span>}
                    </div>
                </div>)}

                <div style={{ width: '100%', height: '400px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            data={combinedSchedule}
                            margin={{
                                top: 40,
                                right: window.innerWidth > 768 ? 20 : -30,
                                left: window.innerWidth > 768 ? 20 : -30,
                                bottom: 5,
                            }}
                            onClick={(e) => {
                                if (e.activePayload && e.activePayload.length) {
                                    const clickedData = e.activePayload[0].payload;
                                    if (!showOnlyHotWater && (esp?.controlPanelOptions?.showColorHeating || showOnlyHeating)) {
                                        handleHourClickHeating(clickedData);
                                    }
                                    if (!showOnlyHeating && (esp?.controlPanelOptions?.showColorHotWaterHeating || showOnlyHotWater)) {
                                        handleHourClickHotWater(clickedData);
                                    }
                                }
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="fullDate"
                                tick={{ fontSize: 12 }}
                                interval={window.innerWidth > 768 ? 2 : 5}
                                allowDuplicatedCategory={false}
                                tickFormatter={(value) => value.split(' ')[2]}
                            />
                            <YAxis
                                yAxisId="left"
                                label={{
                                    value: window.innerWidth > 768 ? 'Pris (kr/kWh)' : '',
                                    angle: -90,
                                    position: 'insideLeft'
                                }}
                                domain={[domainPriceLowest, domainPriceHighest]}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                label={{
                                    value: window.innerWidth > 768 ? 'Temperatur (°C)' : '',
                                    angle: 90,
                                    position: 'insideRight'
                                }}
                                domain={[0, 60]}
                            />
                            <Tooltip
                                content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                        const currentIndex = combinedSchedule.findIndex(item => item.fullDate === label);
                                        const currentEntry = combinedSchedule[currentIndex];
                                        const prevEntry = currentIndex > 0 ? combinedSchedule[currentIndex - 1] : null;

                                        return (
                                            <div className="custom-tooltip bg-white p-2 border border-gray-200 shadow-sm">
                                                <p className="mb-1">{currentEntry.fullDate}</p>
                                                {esp?.controlPanelOptions?.showPrice && <p className="mb-0" style={{ color: COLORS.ELPRIS_TOOLTIP }}>
                                                    Elpris: {currentEntry.price.toFixed(2)} kr/kWh
                                                </p>}
                                                {(showOnlyHeating || (!showOnlyHotWater && esp?.controlPanelOptions?.showTemperature)) && <p className="mb-0" style={{ color: COLORS.INDOORTEMP }}>
                                                    Måltemperatur rumsvärme: {currentEntry.heatingGoalTemp}°C
                                                </p>}
                                                {esp?.controlPanelOptions?.showAwayColor && currentEntry.awayEnds && (<p className="mb-0" style={{ color: COLORS.AWAY }}>
                                                    Bortaläge slutar: {format(awayEnds, 'HH:mm')}
                                                </p>)}
                                                {(showOnlyHotWater || (!showOnlyHeating && esp?.controlPanelOptions?.showTemperature)) && payload.map((pld, index) => {
                                                    if (pld.dataKey === "hotWaterCalculatedTemperature") {
                                                        return (
                                                            <p
                                                                key={index}
                                                                className="mb-0"
                                                                style={{ color: COLORS.HOTWATERTEMP }}
                                                            >
                                                                Uppskattad temperatur varmvatten: {prevEntry?.hotWaterCalculatedTemperature
                                                                    ? `${prevEntry.hotWaterCalculatedTemperature.toFixed(1)}°C → ${currentEntry?.hotWaterCalculatedTemperature?.toFixed(1)}°C`
                                                                    : `${currentEntry?.hotWaterCalculatedTemperature?.toFixed(1)}°C`
                                                                }
                                                            </p>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                {(showOnlyHotWater || (!showOnlyHeating && esp?.controlPanelOptions?.showScheduledHotWater)) && currentEntry?.scheduledHotWaterTimes?.map((time, index) => (
                                                    <p key={index} className="mb-0" style={{ color: COLORS.SCHEDULE_TIME }}>
                                                        Schemalagd tid varmvatten: {format(new Date(time?.iso ? time.iso : time), 'HH:mm')}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />

                            {showOnlyHeating &&
                                <Legend
                                    payload={[
                                        { value: `Borta (${esp?.heating?.tempSettings?.away?.temp?.toFixed(1) ?? 'N/A'}°C)`, type: 'rect', color: COLORS.AWAY },
                                        { value: `Min (${esp?.heating?.tempSettings?.min?.temp?.toFixed(1) ?? 'N/A'}°C)`, type: 'rect', color: COLORS.MIN },
                                        { value: `Ideal (${esp?.heating?.tempSettings?.ideal?.temp?.toFixed(1) ?? 'N/A'}°C)`, type: 'rect', color: COLORS.IDEAL },
                                        { value: `Max (${esp?.heating?.tempSettings?.max?.temp?.toFixed(1) ?? 'N/A'}°C)`, type: 'rect', color: COLORS.MAX },
                                        { value: `Anpassad`, type: 'rect', color: COLORS.CUSTOM },
                                        { value: `Måltemperatur`, type: 'line', color: COLORS.INDOORTEMP },
                                        { value: 'Bortaläge slutar', type: 'star', color: COLORS.AWAY }
                                    ]}
                                />
                            }

                            {showOnlyHotWater &&
                                <Legend
                                    payload={[
                                        { value: 'Elpris', type: 'rect', color: COLORS.ELPRIS },
                                        { value: 'Optimerad värmning', type: 'rect', color: COLORS.HOTWATER_HEATING },
                                        { value: 'Extra värmning', type: 'rect', color: COLORS.HOTWATER_CUSTOM_HEATING },
                                        { value: 'Temperatur', type: 'line', color: COLORS.HOTWATERTEMP },
                                        { value: 'Schemalagd varmvattentid', type: 'star', color: COLORS.SCHEDULE_TIME },
                                        { value: 'Bortaläge slutar', type: 'star', color: COLORS.AWAY }
                                    ]}
                                />
                            }

                            {esp?.controlPanelOptions?.showPrice &&
                                <Bar
                                    yAxisId="left"
                                    dataKey="price"
                                    name="Elpris"
                                    fill={COLORS.DEFAULT}
                                    isAnimationActive={false}
                                >
                                    {combinedSchedule.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={(showOnlyHotWater || (!showOnlyHeating && esp?.controlPanelOptions?.showColorHotWaterHeating)) && entry.isHotWaterHeating ? COLORS.HOTWATER_HEATING :
                                                (showOnlyHotWater || (!showOnlyHeating && esp?.controlPanelOptions?.showColorHotWaterHeating)) && entry.isHotWaterCustomHeating ? COLORS.HOTWATER_CUSTOM_HEATING :
                                                    esp?.controlPanelOptions?.showAwayColor && entry.heatingSetting === "away" ? COLORS.AWAY :
                                                        (showOnlyHeating || (!showOnlyHotWater && esp?.controlPanelOptions?.showColorHeating)) && entry.heatingCustomHour ? COLORS.CUSTOM :
                                                            (showOnlyHeating || (!showOnlyHotWater && esp?.controlPanelOptions?.showColorHeating)) && entry.heatingSetting === "min" ? COLORS.MIN :
                                                                (showOnlyHeating || (!showOnlyHotWater && esp?.controlPanelOptions?.showColorHeating)) && entry.heatingSetting === "ideal" ? COLORS.IDEAL :
                                                                    (showOnlyHeating || (!showOnlyHotWater && esp?.controlPanelOptions?.showColorHeating)) && entry.heatingSetting === "max" ? COLORS.MAX :
                                                                        COLORS.ELPRIS}
                                        />
                                    ))}
                                </Bar>}
                            {(showOnlyHeating || (!showOnlyHotWater && esp?.controlPanelOptions?.showTemperature)) && hasValidSchedule && (
                                <Line
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="heatingGoalTemp"
                                    name="Måltemperatur rumsvärme"
                                    stroke={COLORS.INDOORTEMP}
                                    dot={false}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                />
                            )}
                            {(showOnlyHotWater || (!showOnlyHeating && esp?.controlPanelOptions?.showHotWater)) && hasValidSchedule && (
                                <Line
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="hotWaterCalculatedTemperature"
                                    name="Uppskattad varmvattentemperatur"
                                    stroke={COLORS.HOTWATERTEMP}
                                    dot={false}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                />
                            )}
                            {combinedSchedule
                                .filter(entry => entry.isCurrentHour)
                                .map((entry, index) => (
                                    <ReferenceLine
                                        key={`current-hour-${index}`}
                                        x={entry.fullDate}
                                        yAxisId="left"
                                        stroke={COLORS.DEFAULT}
                                        strokeWidth={2}
                                        label={{
                                            value: 'Nu',
                                            position: 'top',
                                            fill: COLORS.DEFAULT
                                        }}
                                    />
                                ))}
                            {(showOnlyHotWater || (!showOnlyHeating && esp?.controlPanelOptions?.showScheduledHotWater)) && combinedSchedule
                                .filter(entry => entry.scheduledHotWaterTimes && entry.scheduledHotWaterTimes.length > 0)
                                .map((entry, index) => (
                                    <ReferenceLine
                                        key={`ref-${index}`}
                                        x={entry.fullDate}
                                        yAxisId="left"
                                        stroke={COLORS.SCHEDULE_TIME}
                                        strokeWidth={2}
                                        label={{
                                            position: 'top',
                                            fill: COLORS.DEFAULT
                                        }}
                                    />
                                ))}
                            {esp?.controlPanelOptions?.showAwayColor && combinedSchedule
                                .filter(entry => entry.awayEnds)
                                .map((entry, index) => (
                                    <ReferenceLine
                                        key={`ref-${index}`}
                                        x={entry.fullDate}
                                        yAxisId="left"
                                        stroke={COLORS.AWAY}
                                        strokeWidth={2}
                                        label={{
                                            value: 'Hemkomst',
                                            position: 'top',
                                            fill: COLORS.AWAY,
                                            offset: 20
                                        }}
                                    />
                                ))}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>

                {!showOnlyHeating && !showOnlyHotWater &&
                    <div className={`btn-group d-flex mb-2 ${window.innerWidth <= 768 ? 'btn-group-vertical' : ''}`} role="group" aria-label="Button group">
                        <button
                            className={`btn ${esp?.controlPanelOptions?.showPrice ? 'btn-secondary' : 'btn-outline-secondary'} ${window.innerWidth <= 768 ? 'rounded-top' : 'rounded-start'} m-0`}
                            onClick={() => updateUserEsp(esp.id, { controlPanelOptions: { showPrice: !esp?.controlPanelOptions?.showPrice } })}
                        >
                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.ELPRIS, marginLeft: '2px', marginRight: '5px' }}></span>
                            Elpris
                        </button>

                        <button
                            className={`btn ${esp?.controlPanelOptions?.showAwayColor ? 'btn-secondary' : 'btn-outline-secondary'} ${window.innerWidth <= 768 ? 'rounded-bottom' : 'rounded-end'} m-0`}
                            onClick={() => updateUserEsp(esp.id, { controlPanelOptions: { showAwayColor: !esp?.controlPanelOptions?.showAwayColor } })}
                        >
                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.AWAY, marginLeft: '2px', marginRight: '5px' }}></span>
                            Borta
                        </button>
                    </div>
                }

                {!showOnlyHeating && !showOnlyHotWater &&
                    <div className={`btn-group d-flex mb-2 ${window.innerWidth <= 768 ? 'btn-group-vertical' : ''}`} role="group" aria-label="Button group">
                        <button
                            className={`btn ${esp?.controlPanelOptions?.showColorHotWaterHeating ? 'btn-secondary' : 'btn-outline-secondary'} ${window.innerWidth <= 768 ? 'rounded-top' : 'rounded-start'} m-0`}
                            onClick={() => updateUserEsp(esp.id, {
                                controlPanelOptions: {
                                    showColorHotWaterHeating: !esp?.controlPanelOptions?.showColorHotWaterHeating,
                                    showColorHeating: !esp?.controlPanelOptions?.showColorHotWaterHeating ? false : esp?.controlPanelOptions?.showColorHeating
                                }
                            })}
                        >
                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.HOTWATER_HEATING, marginLeft: '2px', marginRight: '5px' }}></span>
                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.HOTWATER_CUSTOM_HEATING, marginLeft: '2px', marginRight: '5px' }}></span>
                            Optimerad och extra vattenvärmning
                        </button>
                        <button
                            className={`btn ${esp?.controlPanelOptions?.showScheduledHotWater ? 'btn-secondary' : 'btn-outline-secondary'} m-0`}
                            onClick={() => updateUserEsp(esp.id, { controlPanelOptions: { showScheduledHotWater: !esp?.controlPanelOptions?.showScheduledHotWater } })}
                        >
                            <span style={{ display: 'inline-block', width: '2px', height: '20px', backgroundColor: COLORS.SCHEDULE_TIME, marginLeft: '6px', marginRight: '6px', marginBottom: '-4px' }}></span>
                            Schemalagd tid för varmvatten
                        </button>
                        <button
                            className={`btn ${esp?.controlPanelOptions?.showHotWater ? 'btn-secondary' : 'btn-outline-secondary'} ${window.innerWidth <= 768 ? 'rounded-bottom' : 'rounded-end'} m-0`}
                            onClick={() => updateUserEsp(esp.id, { controlPanelOptions: { showHotWater: !esp?.controlPanelOptions?.showHotWater } })}
                        >
                            <span style={{ display: 'inline-block', width: '15px', height: '2px', backgroundColor: COLORS.HOTWATERTEMP, marginLeft: '2px', marginRight: '5px', marginBottom: '5px' }}></span>
                            Uppskattad temperatur varmvatten
                        </button>
                    </div>
                }

                {!showOnlyHeating && !showOnlyHotWater &&
                    <div className={`btn-group d-flex ${window.innerWidth <= 768 ? 'btn-group-vertical' : ''}`} role="group" aria-label="Button group">
                        <button
                            className={`btn ${esp?.controlPanelOptions?.showColorHeating ? 'btn-secondary' : 'btn-outline-secondary'} ${window.innerWidth <= 768 ? 'rounded-top' : 'rounded-start'} m-0`}
                            onClick={() => updateUserEsp(esp.id, {
                                controlPanelOptions: {
                                    showColorHeating: !esp?.controlPanelOptions?.showColorHeating,
                                    showColorHotWaterHeating: !esp?.controlPanelOptions?.showColorHeating ? false : esp?.controlPanelOptions?.showColorHotWaterHeating
                                }
                            })}
                        >
                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.MIN, marginLeft: '2px', marginRight: '5px' }}></span>
                            Min {esp?.heating?.tempSettings?.min?.temp?.toFixed(1) ?? 'N/A'}°C


                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.IDEAL, marginLeft: '2px', marginRight: '5px' }}></span>
                            Ideal {esp?.heating?.tempSettings?.ideal?.temp?.toFixed(1) ?? 'N/A'}°C


                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.MAX, marginLeft: '2px', marginRight: '5px' }}></span>
                            Max {esp?.heating?.tempSettings?.max?.temp?.toFixed(1) ?? 'N/A'}°C

                            <span style={{ display: 'inline-block', width: '15px', height: '12px', backgroundColor: COLORS.CUSTOM, marginLeft: '2px', marginRight: '5px' }}></span>
                            Anpassad
                        </button>
                        <button
                            className={`btn ${esp?.controlPanelOptions?.showTemperature ? 'btn-secondary' : 'btn-outline-secondary'} ${window.innerWidth <= 768 ? 'rounded-bottom' : 'rounded-end'} m-0`}
                            onClick={() => updateUserEsp(esp.id, { controlPanelOptions: { showTemperature: !esp?.controlPanelOptions?.showTemperature } })}
                        >
                            <span style={{ display: 'inline-block', width: '15px', height: '2px', backgroundColor: COLORS.INDOORTEMP, marginLeft: '2px', marginRight: '5px', marginBottom: '5px' }}></span>
                            Måltemperatur rumsvärme
                        </button>
                    </div>
                }
            </div >
            <Modal show={showHelp || selectedHourData} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {showHelp && "Tider för värmning och varmvatten"}
                        {selectedHourData && (
                            "Egen temperatur " +
                            format(selectedHourData.hourOriginal, 'd/M HH:mm') +
                            " - " +
                            format(new Date(new Date(selectedHourData.hourOriginal).getTime() +
                                ((selectedHourData.numberOfHours || 1) * 3600000)), 'd/M HH:mm')
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showHelp && showOnlyHeating && <span><p>Här visas planerad inomhustemperatur under kommande timmar med hittills känt elpris. Nya priser dyker upp ca klockan 14.15 dagligen.</p><p>Genom att klicka i diagrammet kan du ställa in anpassade temperaturer under begränsade tider.</p></span>}
                    {showHelp && showOnlyHotWater && <span><p>Här visas planerad värmning av varmvatten under kommande timmar med hittills känt elpris. Nya priser dyker upp ca klockan 14.15 dagligen.</p><p>Genom att klicka i diagrammet kan du lägga till och ta bort extra värmning på valda timmar.</p></span>}
                    {showHelp && !showOnlyHeating && !showOnlyHotWater && <span><p>Här visas planerad värmning av inomhustemperatur och/eller varmvatten under kommande timmar med hittills känt elpris. Nya priser dyker upp ca klockan 14.15 dagligen.</p><p>Klicka på knapparna under diagrammet för att välja vilken information som skall visas. Genom att klicka i diagrammet kan du ställa in anpassade temperaturer eller lägga till extra varmvattenvärmning på valda timmar.</p></span>}
                    {selectedHourData && !showOnlyHotWater && (
                        <div>
                            <div className="d-flex justify-content-between">
                                <div className="text-center">
                                    <label className="label">Antal timmar {selectedHourData.numberOfHours || 1}</label>
                                    <div>
                                        <input type="range" className="form-range" min="1" max="48" id="daterange" value={selectedHourData.numberOfHours || 1} onChange={(e) => setSelectedHourData({ ...selectedHourData, numberOfHours: e.target.value })} />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <label className="label">Måltemperatur {selectedHourData.heatingGoalTemp}°C</label>
                                    <div>
                                        <input type="range" className="form-range" min="0" max="30" id="temprange" value={parseFloat(selectedHourData.heatingGoalTemp).toFixed(1)} onChange={(e) => setSelectedHourData({ ...selectedHourData, heatingGoalTemp: parseFloat(e.target.value).toFixed(1) })} step="0.1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                {selectedHourData && <Modal.Footer>
                    {selectedHourData.isExistingCustomHour && (<button className="btn btn-danger" onClick={handleClear}>Rensa</button>)}
                    <button className="btn btn-secondary" onClick={handleCloseModal}>{"Avbryt"}</button>
                    <button className="btn btn-primary" onClick={() => {
                        saveSelectedHourChanges(selectedHourData);
                        handleCloseModal();
                    }}>Spara</button>
                </Modal.Footer>}
            </Modal>
        </>
    );
};

export default CombinedSchedule;

